import * as React from 'react';
import { ErrorMessagePage } from '@app/components/error-message-page';
import '@app/styles/empty.scss';

const ErrorPage: React.FC = () => {
  return (
    <ErrorMessagePage
      title="Página não encontrada (404)"
      message="A página que você esta tentando acessar não existe."
      backAs="/"
      backLink="/index"
      backLabel="Voltar para a Busca"
    />
  );
};

// export default AboutUs;
export default ErrorPage;
