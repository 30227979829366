import * as React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { Link } from '../../i18n';
import sentry from '@app/util/sentry';
import { UserType } from './graphql-hooks';
const { Sentry } = sentry();

interface OwnProps {
  title?: string;
  message?: string;
  backLink?: string;
  backAs?: string;
  backLabel?: string;
  reportLabel?: string;
  me?: UserType;
  backFn?: () => void;
}

export const ErrorMessagePage: React.FunctionComponent<OwnProps> = ({
  title,
  message,
  backLink,
  backAs,
  backLabel,
  backFn,
  reportLabel,
  me,
}) => {
  let eventId: string | null = '';
  if (typeof window !== 'undefined') {
    eventId = localStorage.getItem('errorEventId');
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100vh"
    >
      <Box mb={2} textAlign="center">
        {title && <Typography variant="h1">{title}</Typography>}
      </Box>
      <Box mb={4} textAlign="center">
        {message && <Typography variant="h2">{message}</Typography>}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        {reportLabel != null && eventId != null && (
          <Box mx={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                let user: any;
                if (me != null) {
                  user = { email: me.email, name: me.fullName };
                }
                Sentry.showReportDialog({ eventId, user });
              }}
            >
              {reportLabel}
            </Button>
          </Box>
        )}
        {backLabel != null && backLink != null && (
          <Link href={backLink} as={backAs} passHref>
            <a style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="primary">
                {backLabel}
              </Button>
            </a>
          </Link>
        )}
        {backFn != null && backLabel != null && (
          <Button variant="contained" color="primary" onClick={backFn}>
            {backLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
};
